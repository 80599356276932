<template>
  <b-sidebar
  v-model="show"
  @change="closeSider"
  right
  shadow
  backdrop
  header-class="container-title-sider"
  width="400px"
  >
    <template #header>
      <div>
        <span class="title-sider">{{ $t("dashboard.whySider") }}</span>
        <feather-icon
        class="close-sider-network"
        icon="XIcon"
        size="20"
        @click="show = false"
        />
      </div>
    </template>
    <div class="mt-2 px-2">
      <span v-html="$t('dashboard.whyConection')"></span>
    </div>
  </b-sidebar> 
</template>
  
<script>
import {
  BSidebar,
} from "bootstrap-vue";

export default {
  name: "SiderWhy",
  components: {
    BSidebar,
  },
  props: {
    show_sider_why: {
      type: Boolean,
    },
  },
  data() {
    return {
      show: this.show_sider_why,
    };
  },
  created() {
  },
  methods: {
    closeSider() {
      this.$emit("close_siderbar_why");
    },
  }
};
</script>